'use strict';

var setVideoControls = function() {

    document.querySelectorAll('.video-player-wrap').forEach(function(container) {
        const video = container.querySelector('video');
        const playPauseButton = container.querySelector('.play-btn');
        const progressBar = container.querySelector('.progress-bar');
        const volumeButton = container.querySelector('.volume-icon');
        const volumeControl = container.querySelector('.volume-bar');
        const fullscreenButton = container.querySelector('.fullscreen-btn');

        const progressBarColor = '#007BFF';
        const progressBarBG = '#dddddd';
        var prevVol = 0;

        function togglePlayPause() {
            if (video.paused || video.ended) {
                video.play();
                playPauseButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="M27.77,128c-8.93,0-16.17-7.24-16.17-16.17V16.17C11.6,7.24,18.84,0,27.77,0s16.17,7.24,16.17,16.17v95.66c0,8.93-7.24,16.17-16.17,16.17Z"/><path d="M100.23,128c-8.93,0-16.17-7.24-16.17-16.17V16.17c0-8.93,7.24-16.17,16.17-16.17s16.17,7.24,16.17,16.17v95.66c0,8.93-7.24,16.17-16.17,16.17Z"/></svg>';
            } else {
                video.pause();
                playPauseButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="M12.79,10.85v106.29c0,8.74,10.22,13.89,17.7,8.91l79.86-53.15c6.48-4.31,6.48-13.51,0-17.81L30.49,1.95c-7.48-4.98-17.7.17-17.7,8.91Z"/></svg>';
            }
        }

        function updateProgressBar() {
            const playbackTime = parseFloat(((video.currentTime / video.duration) * 100).toFixed(1));
            progressBar.value = playbackTime;
            progressBar.style.background = 'linear-gradient(to right, '+progressBarColor+' '+playbackTime+'%, '+progressBarBG+' '+playbackTime+'%)';
        }
    
        function seekVideo() {
            const seekTime = parseFloat(((progressBar.value / 100) * video.duration).toFixed(1));
            progressBar.style.background = 'linear-gradient(to right, '+progressBarColor+' '+progressBar.value+'%, '+progressBarBG+' '+progressBar.value+'%)';
            video.currentTime = seekTime;
        }

        function changeVolume() {
            const volumeInput = volumeControl.value;
            const percentValue = volumeInput * 100;
            video.volume = volumeInput;
            prevVol = video.volume;
            volumeControl.style.background = 'linear-gradient(to right, '+progressBarColor+' '+percentValue+'%, '+progressBarBG+' '+percentValue+'%)';
        }

        function toggleFullscreen() {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                video.requestFullscreen();
            }
        }

        function toggleVolume() {
            if (!video.muted) {
                video.muted = true;

                prevVol = video.volume;
                video.volume = 0;
                volumeControl.value = 0;
                volumeControl.style.background = 'linear-gradient(to right, '+progressBarColor+' 0%, '+progressBarBG+' 0%)';
                volumeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="M75.01,97.51v12.14c0,6.68-7.07,10.6-12.25,6.8l-34.02-25.01c-.44.09-.88.12-1.34.12H7.83c-4.33,0-7.83-3.5-7.83-7.83v-29.38c0-4.33,3.5-7.85,7.83-7.85h16.21l50.97,50.99Z"/><path d="M125.12,114.83c2.35,2.35,2.35,6.13,0,8.48-1.17,1.17-2.7,1.75-4.22,1.75s-3.08-.58-4.24-1.75L6.5,13.16c-2.33-2.33-2.33-6.13,0-8.47,2.35-2.33,6.13-2.35,8.48,0l30.01,30.01,17.76-13.08c5.18-3.8,12.25.14,12.25,6.8v36.29l15.08,15.08c1.91-3.11,2.93-6.89,2.93-10.89,0-5.83-2.21-11.21-6.08-14.72-2.44-2.23-2.63-6.01-.41-8.47,2.23-2.44,6.03-2.62,8.47-.39,6.36,5.78,10,14.37,10,23.58,0,7.23-2.24,14.09-6.31,19.5l7.88,7.88c6.06-7.26,9.46-16.99,9.46-27.38,0-12.25-4.72-23.58-12.96-31.05-2.44-2.23-2.62-6.03-.39-8.47,2.23-2.44,6.01-2.63,8.47-.41,10.71,9.76,16.84,24.3,16.84,39.93,0,13.59-4.65,26.39-12.9,35.9l10.02,10.02Z"/></svg>'
            } else {
                video.muted = false;

                const prevPercent = prevVol * 100;
                video.volume = prevVol;
                volumeControl.value = prevVol;
                volumeControl.style.background = 'linear-gradient(to right, '+progressBarColor+' '+prevPercent+'%, '+progressBarBG+' '+prevPercent+'%)';
                volumeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="M75.01,23.39v81.21c0,6.67-7.07,10.61-12.25,6.8l-34.02-25c-.43.08-.88.12-1.35.12H7.83c-4.32,0-7.83-3.5-7.83-7.83v-29.38c0-4.33,3.51-7.83,7.83-7.83h19.56c.46,0,.91.04,1.35.12l34.02-25c5.18-3.81,12.25.13,12.25,6.8Z"/><path d="M107.12,105.48c-1.63,0-3.25-.66-4.43-1.96-2.23-2.45-2.05-6.24.4-8.46,8.22-7.48,12.94-18.8,12.94-31.06s-4.72-23.58-12.94-31.06c-2.45-2.23-2.63-6.02-.4-8.46,2.23-2.45,6.01-2.63,8.46-.4,10.71,9.75,16.85,24.3,16.85,39.92s-6.14,30.17-16.85,39.92c-1.15,1.04-2.59,1.56-4.03,1.56Z"/><path d="M90.97,89.15c-1.63,0-3.25-.66-4.43-1.96-2.23-2.45-2.05-6.24.4-8.46,3.87-3.52,6.08-8.88,6.08-14.73s-2.22-11.21-6.08-14.73c-2.45-2.23-2.63-6.02-.4-8.46,2.23-2.45,6.02-2.63,8.46-.4,6.35,5.78,10,14.38,10,23.59s-3.64,17.8-10,23.59c-1.15,1.04-2.59,1.56-4.03,1.56Z"/></svg>'
            }
        }

        toggleVolume();
        togglePlayPause();
    
        playPauseButton.addEventListener('click', togglePlayPause);
        video.addEventListener('timeupdate', updateProgressBar);
        progressBar.addEventListener('input', seekVideo);
        volumeControl.addEventListener('input', changeVolume);
        fullscreenButton.addEventListener('click', toggleFullscreen);
        volumeButton.addEventListener('click', toggleVolume);

    });

}

var init = function() {
    setVideoControls();
};

module.exports = {
    init: init
};